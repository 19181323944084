export const formatTime = (timer) => {
  const getHundreths = `0${timer}`.slice(-1);
  const seconds = `${Math.floor(timer / 10)}`;
  // const getSeconds = `0${(seconds % 60)}`.slice(-2)
  // const minutes = `${Math.floor(timer / 600)}`
  // const getMinutes = `0${minutes % 60}`.slice(-2)
  return `${seconds}.${getHundreths}`;
};

export const GetTimestamp = () => {
  var isPerformanceSupported =
    window.performance &&
    window.performance.now &&
    window.performance.timing &&
    window.performance.timing.navigationStart;
  var timeStampInMs = isPerformanceSupported
    ? window.performance.now() + window.performance.timing.navigationStart
    : Date.now();
  console.log("%cGetTimestamp", "color:red", timeStampInMs);
  return timeStampInMs;
};
