
import { useState, useEffect } from 'react';
import Typography from "@material-ui/core/Typography";
import Settings from './Settings'
import { withStyles } from "@material-ui/core/styles";
import Container from '@mui/material/Container';
const styles = (theme) => ({
  fullScreen: {
    textAlign: "center",
    // position: "fixed",
    // top: "0",
    // left: "0",
    // bottom: "0",
    // right: "0",
    // overflow: "auto",
    // background: "lime",
  }
});
function ElixirCount(props) {
  const { classes } = props;
  const [clashData, setClashData] = useState([]);
  const royaleAPI = 'https://royaleapi.github.io/cr-api-data/json/cards.json';
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(royaleAPI);
      const newData = await response.json();
      setClashData(newData);
    };
    fetchData();
  }, []);
  if (clashData) {
    return (
      <Container className={classes.fullScreen}>
        <Typography variant="h4" component="h2">
          Elixir Count
        </Typography>
        <Settings clashData={clashData} setClashData={setClashData} />
      </Container>
    );
  } else {
    return null;
  }
}
export default withStyles(styles)(ElixirCount);;