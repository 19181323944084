import * as React from 'react';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Typography from "@material-ui/core/Typography";
import Deck from './Deck'
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  container: {
    // padding: "0 15px"
  }
});
function valuetext(value) {
  return `${value} Cards`;
}
function DiscreteSlider(props) {
  const { classes } = props;
  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    }
  ];
  const marksRounds = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 15,
      label: '15',
    },
  ];
  const [numberOfCards, setNumberOfCards] = React.useState([1]);
  const updateNumberOfCards = (e, data) => {
    setNumberOfCards(data);
  };
  const [numberOfRounds, setNumberOfRounds] = React.useState([10]);
  const updateNumberOfRounds = (e, data) => {
    setNumberOfRounds(data);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        className={classes.container}
      >
        <Grid
          item
          md={6}
          xs={6}
        >
          <Typography id="numberOfCards" gutterBottom>
            Cards
          </Typography>
          <Slider
            aria-label="# of Cards"
            defaultValue={1}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={3}
            marks={marks}
            onChange={updateNumberOfCards}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
        >
          <Typography id="numberOfRounds" gutterBottom>
            Rounds
          </Typography>
          <Slider
            aria-label="# of Rounds"
            defaultValue={10}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={5}
            min={5}
            max={20}
            marks={marksRounds}
            onChange={updateNumberOfRounds}
          />
        </Grid>
      </Grid>
      <Deck clashData={props.clashData} setClashData={props.setClashData} numberOfCards={numberOfCards} numberOfRounds={numberOfRounds} />
    </>
  );
}
export default withStyles(styles)(DiscreteSlider);