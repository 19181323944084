import React from "react";
import Box from "@mui/material/Box";

function RenderBoardSide(props) {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
      }}
      className="board"
    >
      {props.side}
    </Box>
  );
}

export default RenderBoardSide;
