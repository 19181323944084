import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PollIcon from "@mui/icons-material/Poll";
import StyleIcon from "@mui/icons-material/Style";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SportsIcon from "@mui/icons-material/Sports";
export const mainListItems = (
  <React.Fragment>
    <ListItemButton component="a" href="/trainer">
      <ListItemIcon sx={{ color: "white" }}>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Trainer" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon sx={{ color: "white" }}>
        <YouTubeIcon />
      </ListItemIcon>
      <ListItemText primary="Youtubers" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon sx={{ color: "white" }}>
        <PollIcon />
      </ListItemIcon>
      <ListItemText primary="Polls" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon sx={{ color: "white" }}>
        <StyleIcon />
      </ListItemIcon>
      <ListItemText primary="Cards" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon sx={{ color: "white" }}>
        <SmartToyIcon />
      </ListItemIcon>
      <ListItemText primary="Mini Games" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon sx={{ color: "white" }}>
        <FormatQuoteIcon />
      </ListItemIcon>
      <ListItemText primary="Wisdom" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon sx={{ color: "white" }}>
        <SportsIcon />
      </ListItemIcon>
      <ListItemText primary="Coaching" />
    </ListItemButton>
  </React.Fragment>
);
