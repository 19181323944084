// import { useState } from "react";
import Box from "@mui/material/Box";
import RenderCard from "./RenderCard";
import IconButton from "@mui/material/IconButton";

import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
function RenderHand(props) {
  // console.log("%cProps", "color: green", props.randomKeys);
  let card = {};
  let rows = [];
  // const [startTimestamp, setStartTimestamp] = useState(0);

  for (let i = 0; i < 4; i++) {
    card = props.clashData[props.randomKeys[i]]
      ? props.clashData[props.randomKeys[i]]
      : null;
    if (card) {
      // console.log("card", i);

      rows.push(
        <RenderCard
          key={i}
          card={card}
          startTimestamp={props.startTimestamp}
          setStartTimestamp={props.setStartTimestamp}
          startCard={props.startCard}
          setStartCard={props.setStartCard}

          // onNameChange={(event) => props.setStartTimestamp(event.target.value)}
        />
      );
    }
  }
  return (
    <Box
      sx={{
        display: "grid",
        gridAutoColumns: "1fr",
        marginBottom: "36px",
      }}
    >
      <Box
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        sx={{
          gridRow: "1",
          gridColumn: "1",
          background: "#eee",
          color: "#6B4D32",
        }}
      >
        <IconButton
          aria-label="start"
          size="large"
          onClick={() => {
            console.log("game started");
            props.setStartGameTimestamp(Date.now());
          }}
        >
          <SlowMotionVideoIcon color="error" />
        </IconButton>
      </Box>

      <Box
        sx={{
          background: "#6B4D32",
          display: "grid",
          gridRow: "1",
          gridColumn: "span 4",
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        {props.startGameTimestamp && rows}
      </Box>
    </Box>
  );
}

export default RenderHand;
