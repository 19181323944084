import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Rounds from "./Rounds";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import RenderRound from "./RenderRound";
import CheckAnswer from "./CheckAnswer";
import { Button } from "@mui/material";
import Timer from "../Timer";
const styles = (theme) => ({
  input: {
    backgroundColor: "#fff",
    textAlign: "center",
    padding: "15px",
    fontSize: "45px",
    fontWeight: "800",
    margin: "25px auto",
    width: "90%",
  },
  inputContainer: {
    textAlign: "center",
    margin: "20px",
  },
  card: {
    backgroundColor: "#eee",
  },
  paperContainer: {
    padding: "2em",
  },
  buttonElixir: {
    width: "100%",
    height: "100px",
  },
});
function Input(props) {
  const { classes } = props;
  const [guesses, setGuesses] = useState([]);
  const [itemName, setItemName] = useState("");
  const [currentRound, setCurrentRound] = useState(1);
  const [gameOver, setGameOver] = useState(0);
  let buttons = [];
  let card = {};
  let start = (currentRound - 1) * Number(props.numberOfCards);
  let elixir = 0;
  let end = start + Number(props.numberOfCards);
  for (let i = start; i < end; i++) {
    card = props.clashData[props.randomKeys[i]]
      ? props.clashData[props.randomKeys[i]]
      : null;
    if (card) {
      elixir = elixir + card.elixir;
    }
  }
  const addGuess = (event) => {
    event.preventDefault();
    setCurrentRound(currentRound + 1);
    setGuesses([
      ...guesses,
      {
        id: guesses.length,
        guess: itemName,
        answer: elixir,
        score: itemName === elixir ? 10 : 0,
        timer: document.getElementById("timer").value,
      },
    ]);
    setItemName("");
  };
  const rndInt = Math.floor(Math.random() * 4);
  let elixirStart = elixir - rndInt > 0 ? elixir - rndInt : elixir;
  let elixirEnd = elixirStart + 4;
  for (let i = elixirStart; i < elixirEnd; i++) {
    buttons.push(
      <Grid item xs={3} key={i}>
        <Button
          onClick={(e) => setItemName(i)}
          variant="contained"
          type="submit"
          className={classes.buttonElixir}
        >
          {i}
        </Button>
      </Grid>
    );
  }
  // check game over
  if (props.numberOfRounds === currentRound) {
    console.log("ccc", currentRound);
    setGameOver(1);
    console.log("ssss", gameOver);
  }
  return (
    <>
      <Paper
        variant="outlined"
        square={true}
        className={classes.paperContainer}
      >
        <Timer />
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
          id="deck"
        >
          <RenderRound
            currentRound={currentRound}
            numberOfCards={Number(props.numberOfCards)}
            clashData={props.clashData}
            randomKeys={props.randomKeys}
          />
        </Grid>
      </Paper>
      <div className={classes.inputContainer}>
        <form onSubmit={addGuess}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {buttons}
          </Grid>
        </form>
      </div>
      <Rounds
        currentRound={currentRound}
        numberOfRounds={props.numberOfRounds}
      />
      <CheckAnswer
        guesses={guesses}
        clashData={props.clashData}
        randomKeys={props.randomKeys}
        currentRound={currentRound}
        numberOfCards={props.numberOfCards}
        gameOver={gameOver}
      />
    </>
  );
}
export default withStyles(styles)(Input);
