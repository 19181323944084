// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import CardMedia from '@mui/material/CardMedia';
import { withStyles } from "@material-ui/core/styles";
import RenderCard from "./RenderCard";
const styles = (theme) => ({});
const RenderRound = (props) => {
  let rows = [];
  let card = {};
  let start = (props.currentRound - 1) * Number(props.numberOfCards);
  let end = start + Number(props.numberOfCards);
  for (let i = start; i < end; i++) {
    card = props.clashData[props.randomKeys[i]]
      ? props.clashData[props.randomKeys[i]]
      : null;
    if (card) {
      rows.push(
        <RenderCard
          key={i}
          card={card}
          location="50"
          showElixir={props.showElixir}
        />
      );
    }
  }
  return <>{rows}</>;
};
export default withStyles(styles)(RenderRound);
