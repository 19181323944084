import React from 'react';
import useTimer from '../hooks/useTimer';
import { formatTime } from '../utils';
import './Timer.css';
const Timer = () => {
  const { timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset } = useTimer(0)
  return (
    <>
      <input
        id="timer"
        name="timer"
        type="text"
        // onChange={e => setItemName(e.target.value)}
        value={formatTime(timer)}
        readOnly="readonly"
      // onKeyDown={handleKeyPress}
      />
      <div className='buttons'>
        {
          !isActive && !isPaused ?
            <button onClick={handleStart}>Start</button>
            : (
              isPaused ? <button onClick={handlePause}>Pause</button> :
                <button onClick={handleResume}>Resume</button>
            )
        }
        <button onClick={handleReset} disabled={!isActive}>Reset</button>
      </div>
    </>
  );
}
export default Timer;