// import { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  container: {
    background: "#444",
    textAlign: "center",
    color: "#fff",
    padding: "1em"
  }
});
function Rounds(props) {
  const { classes } = props;
  return (
    <div className={classes.container}>
      Round {props.currentRound} of {props.numberOfRounds}
    </div>
  );
};
export default withStyles(styles)(Rounds);