import { useState } from "react";
import Item from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { GetTimestamp } from "../../utils";
import { withStyles } from "@material-ui/core/styles";
import cardBack from "../../assets/images/cardBack.jpg";
const styles = (theme) => ({});
const picUrl = "https://cdn.royaleapi.com/static/img/cards-150/";
let image = null;
const RenderCard = (props) => {
  const [isActive, setIsActive] = useState(false);
  const StartCount = (event, key) => {
    if (!props.startCard) {
      setIsActive((current) => !current);
      props.setStartTimestamp(GetTimestamp());
      props.setStartCard(key);
    }
  };
  if (props.card === undefined) {
    return null;
  }
  // const { classes } = props;
  image =
    props.card.key !== undefined && isActive
      ? `${picUrl}${props.card.key}.png`
      : cardBack;
  // console.log("image", image);
  return (
    <Item sx={{ padding: "4px" }}>
      <Button
        onClick={(event) => StartCount(event, props.card.key)}
        key={props.card.key}
        sx={{
          backgroundColor: isActive ? "#567BEE" : "#49DEF9",
          width: "100%",
          padding: "0 2.5px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "92px",
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></Box>
      </Button>
    </Item>
  );
};
export default withStyles(styles)(RenderCard);
