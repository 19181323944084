import { useState, useEffect } from "react";
import RenderHand from "./RenderHand";
import RenderBoard from "./RenderBoard";
import Backdrop from "@mui/material/Backdrop";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { GetTimestamp } from "../../utils";
import { withStyles } from "@material-ui/core/styles";
// import imageBg from "../../assets/images/board.jpeg";
const picUrl = "https://cdn.royaleapi.com/static/img/cards-150/";
let image = null;
const styles = {};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  color: "red",
  p: 4,
  textAlign: "center",
};
const elapsed = {
  color: "darkgray",
  fontSize: ".8rem",
  marginBottom: "10px",
};
const score = {
  color: "purple",
  fontSize: "1.5rem",
  lineHeight: "5rem",
  bgcolor: "orange",
  margin: "1em",
};
function ReactionTime(props) {
  // const { classes } = props;
  const x = 5;
  const y = 6;
  const [clashData, setClashData] = useState([]);
  const [board, setBoard] = useState([]);
  const [randomKeys, setRandomKeys] = useState([]);
  const [startGameTimestamp, setStartGameTimestamp] = useState(0);
  const [stopGameTimestamp, setStopGameTimestamp] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState(0);
  const [startCard, setStartCard] = useState("");
  const [stopTimestamp, setStopTimestamp] = useState([]);
  const [open, setOpen] = useState(true);
  const [progress, setProgress] = useState(100);
  // const [results, setResults] = useState([]);
  const handleClose = () => {
    setClashData([]);
    setProgress(100);
    setOpen(false);
    setStopTimestamp([]);
    setRandomKeys([]);
    setStartGameTimestamp(GetTimestamp());
    // insert start
    setStopTimestamp([
      {
        id: "start",
        time: GetTimestamp(),
      },
    ]);
  };
  const royaleAPI = "https://royaleapi.github.io/cr-api-data/json/cards.json";
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  stopGameTimestamp &&
    console.log("total", stopGameTimestamp - startGameTimestamp);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(royaleAPI);
      const newData = await response.json();
      setClashData(newData);
    };
    const generateRandomKeys = () => {
      let rk = Object.keys(clashData)
        .map(
          (e, i, a, j = ~~(Math.random() * (a.length - i) + i)) => (
            // eslint-disable-next-line
            ([a[i], a[j]] = [a[j], a[i]]), a[i]
          )
        )
        .slice(0, 8);
      setRandomKeys(rk);
    };
    clashData.length === 0 && fetchData();
    randomKeys.length === 0 && generateRandomKeys();
  }, [clashData, randomKeys]);
  useEffect(() => {
    const StopCount = (event, key) => {
      key && console.log("startCARD", startCard, key.key);
      if (key && key.key === startCard) {
        if (stopTimestamp.length) {
          let prev = stopTimestamp.length - 1;
          let elapsed = (GetTimestamp() - stopTimestamp[prev].time) / 1000;
          elapsed = Math.round((elapsed + Number.EPSILON) * 100) / 100;

          setStopTimestamp([
            ...stopTimestamp,
            { id: key.key, time: GetTimestamp(), elapsed: elapsed },
          ]);
        }
        setStartCard("");
      } else {
        console.log("Wrong");
      }
      // key && console.log("STOP", timeStampInMs, key.key);
    };
    const generateBoard = () => {
      let boardArray = [];
      // let cardName = "s";
      for (let i = 0; i < x * y; i++) {
        image =
          clashData[randomKeys[i]] !== undefined && startCard
            ? `${picUrl}${clashData[randomKeys[i]].key}.png`
            : null;
        boardArray.push(
          <button
            onClick={(event) => StopCount(event, clashData[randomKeys[i]])}
            key={i}
          >
            <Box
              sx={{
                width: "100%",
                height: "90px",
                backgroundImage: `url(${image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></Box>
          </button>
        );
      }
      shuffleArray(boardArray);
      setBoard(boardArray);
    };
    startGameTimestamp > 0 && generateBoard();
  }, [clashData, startCard, startGameTimestamp, stopTimestamp, randomKeys]);
  useEffect(() => {
    // check for end of game
    if (stopTimestamp.length === 5) {
      setStopGameTimestamp(GetTimestamp());
      setOpen(true);
    }
  }, [stopTimestamp]);
  useEffect(() => {
    const timer = setInterval(() => {
      startCard &&
        setProgress((oldProgress) => {
          if (oldProgress === 0) {
            // setProgress(100);
            return 0;
          }
          // const diff = Math.random() * 10;
          // const diff = 10;
          return Math.min(oldProgress - 1, 100);
        });
      // startCard && console.log("progress", progress);
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, [startCard]);
  useEffect(() => {
    console.log("stopTimestamp", stopTimestamp);
  }, [stopTimestamp]);
  // console.log("progress", progress);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
        padding: "0 5px",
        // backgroundColor: "floralwhite",
        // backgroundImage: `url(${imageBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "bottom",
        maxWidth: "412px",
        margin: "auto",
      }}
    >
      <RenderBoard
        board={board}
        startTimestamp={startTimestamp}
        stopTimestamp={stopTimestamp}
        startGameTimestamp={startGameTimestamp}
        setStopTimestamp={setStopTimestamp}
        setStartTimestamp={setStartTimestamp}
        startCard={startCard}
        progress={progress}
        x={x}
        y={y}
      />
      <RenderHand
        randomKeys={randomKeys}
        clashData={clashData}
        startTimestamp={startTimestamp}
        setStartTimestamp={setStartTimestamp}
        startGameTimestamp={startGameTimestamp}
        setStartGameTimestamp={setStartGameTimestamp}
        startCard={startCard}
        setStartCard={setStartCard}
        stopTimestamp={stopTimestamp}
        setStopTimestamp={setStopTimestamp}
      />
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <Box sx={style}>
          {" "}
          <Typography>Reaction Time</Typography>
          <Typography sx={score}>
            {stopGameTimestamp > 0 && progress}
          </Typography>
          {stopGameTimestamp > 0 &&
            stopTimestamp.map(
              (e) =>
                e.id !== "start" && (
                  <Typography sx={elapsed} key={e.id}>
                    {e.id} {e.elapsed}
                  </Typography>
                )
            )}
          {/* (stopGameTimestamp - startGameTimestamp) / 1000} */}
          <Button variant="outlined" onClick={handleClose}>
            New Game
          </Button>
        </Box>
      </Backdrop>
    </Box>
  );
}
export default withStyles(styles)(ReactionTime);
