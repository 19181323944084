import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  card: {
    backgroundColor: "#eee",
  },
  media: {
    backgroundColor: "#eee",
    width: "100%",
    height: "100%"
  },
  cardContent: {
    padding: "2.5px",
    fontSize: ".7em",
  }
});
const picUrl = 'https://cdn.royaleapi.com/static/img/cards-150/';
let image = null;
const RenderCard = (props) => {
  if (props.card === undefined) { return null }
  const { classes } = props;
  image = (props.card.key !== undefined) ? `${picUrl}${props.card.key}.png` : null;
  return (
    <Grid item xs={3}>
      <Card
        variant="outlined"
        className={classes.card}>
        <CardMedia
          component="img"
          image={image}
          className={`card cardFront ${classes.media}`}
        />
        <div className={classes.cardContent}>
          {(props.showElixir) ? props.card.elixir : null}
        </div>
      </Card>
    </Grid>
  )
}
export default withStyles(styles)(RenderCard);