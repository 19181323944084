import "./App.css";
// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";

import Dashboard from "./Dashboard";
import ElixirCount from "./Components/ElixirCount/ElixirCount";
import ReactionTime from "./Components/ReactionTime/ReactionTime";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="elixir" element={<ElixirCount />} />
        <Route path="reaction-time" element={<ReactionTime />} />
        <Route path="contact" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}
export default App;
