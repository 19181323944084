import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
// import Deck from '../Cards/Deck'
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  card: {
    backgroundColor: "#eee",
  }
});
const picUrl = 'https://cdn.royaleapi.com/static/img/cards-150/';
function Cards(props) {
  const { classes } = props;
  return (
    <div className="cards-list">
      {/* <Deck clashData={props.clashData} setClashData={props.setClashData} cardCount="8" /> */}
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {props.clashData.map((element) => {
          return (
            <Grid item xs={3} sm={2} md={1} element={element} key={element.id} className="cardLinksbody">
              <Card variant="outlined">
                <CardMedia
                  component="img"
                  image={`${picUrl}${element.key}.png`}
                  alt={element.name}
                  className={classes.card}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
export default withStyles(styles)(Cards);