import { useState, useRef } from 'react';
const useTimer = (initialState = 0) => {
  const [timer, setTimer] = useState(initialState)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const countRef = useRef(null)
  const handleStart = () => {
    setIsActive(true)
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 100)
    // document.getElementById("guess").focus()
    document.getElementById("deck").classList.add('started')
  }
  const handlePause = () => {
    clearInterval(countRef.current)
    setIsPaused(false)
  }
  const handleResume = () => {
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 100)
  }
  const handleReset = () => {
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(false)
    setTimer(0)
  }
  return { timer, isActive, isPaused, handleStart, handlePause, handleResume, handleReset }
}
export default useTimer