import React from "react";
// import ReactDOM from "react-dom";
import * as ReactDOMClient from "react-dom/client";

import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
const root = ReactDOMClient.createRoot(document.getElementById("root"));

// const container = document.getElementbyId("root");
// create a root
// const root = ReactDOM.createRoot(container);
// render app to root
root.render(<App />);

// ReactDOM.render(<App />, document.getElementById("root"));
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
