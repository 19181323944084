import React, { useState, useEffect } from "react";
function CardList() {
  const [cards, setCards] = useState([]);
  useEffect(() => {
    async function fetchCards() {
      try {
        const response = await fetch("https://api.clashroyale.com/v1/cards", {
          mode: "no-cors",
          headers: {
            Authorization:
              'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiIsImtpZCI6IjI4YTMxOGY3LTAwMDAtYTFlYi03ZmExLTJjNzQzM2M2Y2NhNSJ9.eyJpc3MiOiJzdXBlcmNlbGwiLCJhdWQiOiJzdXBlcmNlbGw6Z2FtZWFwaSIsImp0aSI6IjlhOGNkYTc0LTQyN2EtNDYwYS05NmNmLTBlMTRiMTQyYTRhYiIsImlhdCI6MTY4NTY1MjgxOSwic3ViIjoiZGV2ZWxvcGVyLzQzZmE2N2IyLTIwMWMtYzVkYy1hNzA5LTk3MTJjNDUwYTZhYiIsInNjb3BlcyI6WyJyb3lhbGUiXSwibGltaXRzIjpbeyJ0aWVyIjoiZGV2ZWxvcGVyL3NpbHZlciIsInR5cGUiOiJ0aHJvdHRsaW5nIn0seyJjaWRycyI6WyIxMDQuMjEuMS4xNjMiXSwidHlwZSI6ImNsaWVudCJ9XX0.p1PDzbQkyqBdfaCcuimkCi9jAIMAvoejMQ4T3321HLp6qGsz4UZN4tKlKfk03G5rmatMb4BA7aXCvJ-ux-gsog',
          },
        });
        const data = await response.json();
        setCards(data.items);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    }
    fetchCards();
    console.log("terst");
  }, []);
  return (
    <div>
      <h1>Clash Royale Card List</h1>
      {cards.map((card) => (
        <div key={card.id}>
          <h3>{card.name}</h3>
          <img
            src={card.iconUrls.medium}
            alt={card.name}
          />
        </div>
      ))}
    </div>
  );
}
export default CardList;