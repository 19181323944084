import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { mainListItems } from "./listItems";
import logo from "./assets/images/logo.png";
import Hero from "./Hero";
import { Link } from "react-router-dom";
// import Youtubers from "./Youtubers";
import Container from "@mui/material/Container";
import Blitz from "./Components/Blitz/Blitz"
import CardList from "./Components/ListCards";
import { Card } from "@material-ui/core";
const drawerWidth = 240;
const styles = (theme) => ({
  root: {
    display: "flex",
    fontFamily: 'Magic',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    background: "#488bf4",
    boxShadow: "0 2px 2px 0px rgb(0 0 0 / 12%)",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    color: "white",
    background: "#fb4a9d",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    color: "white",
    background: "#292929",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // width: theme.spacing(7),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    height: "100vh",
    overflow: "auto",
    background: "#f0f2f2",
  },
  container: {
    margin: "25px auto",
    background: "white",
    borderRadius: "5px",
    boxShadow: "0 2px 2px 0px rgb(0 0 0 / 12%)",
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: "90px",
    position: "fixed",
    right: "0",
    top: "0",
    margin: "5px 15px 0 0",
  },
  miniGame: {
    padding: "0",
    textAlign: "center",
    marginBottom: "240px"
  }
});
class Dashboard extends React.Component {
  state = {
    open: false,
  };
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };
  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={logo}
              alt="Clash Royale Trainer"
              className={classes.logo}
            />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            ),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Hero />
                    <CardList />
          <ul>
            <li>
              <Link to="elixir">Elixir Count</Link>
            </li>
            <li>
              <Link to="reaction-time">Reaction Time</Link>
            </li>
          </ul>
          <Container className={classes.container}>
            <Typography variant="h4" gutterBottom component="h2">
              Welcome
            </Typography>
            <Blitz />
            <Typography variant="h4" gutterBottom component="h2">
              Trainer
            </Typography>
          </Container>
          {/* <Youtubers /> */}
        </main>
      </div>
    );
  }
}
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Dashboard);