// import { useEffect, useState } from "react";
import * as React from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import RenderBoardSide from "./RenderBoardSide";
import { withStyles } from "@material-ui/core/styles";
const styles = {
  button: {
    padding: "0",
    // width: "100%",
    // height: "17.5px",
    borderRadius: "0",
    border: 0,
    background: "#A4C24A",
  },
};
const RenderBoard = (props) => {
  const half = Math.ceil(props.board.length / 2);
  const boardOppenent = props.board.slice(0, half);
  const boardSelf = props.board.slice(half, props.board.length);
  let timeElapsed = 0;
  if (props.stopTimestamp > props.startTimestamp && props.startTimestamp > 0) {
    timeElapsed =
      Math.round(
        ((props.stopTimestamp - props.startTimestamp) / 1000 + Number.EPSILON) *
          100
      ) / 100;
  }
  return (
    <Box sx={{ margin: "0 10px 20px", border: "2px solid white" }}>
      <RenderBoardSide side={boardOppenent} />
      <Box
        sx={{
          backgroundColor: "#2089B7",
          textAlign: "center",
          padding: ".5em",
        }}
      >
        {props.stopTimestamp && props.progress}
        {timeElapsed > 0 && timeElapsed}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          // p: 1,
          // m: 1,
          // bgcolor: "background.paper",
          // borderRadius: 1,
        }}
      >
        <Box sx={{ width: "50%" }}>
          <LinearProgress
            variant="determinate"
            value={100 - props.progress}
            color="error"
          />
        </Box>

        <Box sx={{ width: "50%" }}>
          <LinearProgress
            variant="determinate"
            value={props.progress + 100}
            color="error"
          />
        </Box>
      </Box>

      <RenderBoardSide side={boardSelf} />
    </Box>
  );
};
export default withStyles(styles)(RenderBoard);
