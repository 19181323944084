//Wrapper.js
import { useState, useEffect } from 'react';
import Cards from '../Cards/Cards';
import Typography from "@material-ui/core/Typography";
function Wrapper() {
  const [clashData, setClashData] = useState([]);
  useEffect(() => {
    const royaleAPI = 'https://royaleapi.github.io/cr-api-data/json/cards.json';
    fetch(royaleAPI)
      .then((res) => res.json())
      .then((data) => {
        setClashData(data);
      })
      .catch(console.error);
  }, []);
  return (
    <>
      <Typography variant="h4" gutterBottom component="h2">
        BLITZ
      </Typography>
      <Cards clashData={clashData} setClashData={setClashData} />
    </>
  );
}
export default Wrapper;