// import { useState } from 'react';
import * as React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RenderRound from './RenderRound';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
const styles = (theme) => ({
  container: {
    // background: "#303",
    textAlign: "center",
    color: "#fff",
  }
});
const modal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function CheckAnswer(props) {
  const { classes } = props;
  let answerRows = [];
  let totalScore = 0;
  console.log(props.guesses)
  answerRows = props.guesses.map(item => (
    <TableRow
      key={item.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: `${Number(item.answer) === Number(item.guess) ? '#529F5C20' : '#DC563F20'}` }}
    >
      <TableCell align="center" component="th" scope="row">
        {item.id + 1}
      </TableCell>
      <TableCell align="center" style={{ minWidth: '100px' }}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <RenderRound currentRound={item.id + 1} numberOfCards={Number(props.numberOfCards)} clashData={props.clashData} randomKeys={props.randomKeys} showElixir="1" />
        </Grid>
      </TableCell>
      <TableCell align="center">
        {item.answer}
      </TableCell>
      <TableCell align="center">{item.guess}</TableCell>
      <TableCell align="center">{((item.id > 0) ? (Number(item.timer) - Number(props.guesses[(item.id - 1)].timer)).toFixed(1) : item.timer)}</TableCell>
      <TableCell align="center">{item.timer}</TableCell>
      <TableCell align="center">{(5 * props.numberOfCards) - ((item.id > 0) ? (Number(item.timer) - Number(props.guesses[(item.id - 1)].timer)).toFixed(1) : item.score)}{totalScore}</TableCell>
    </TableRow>
  ))
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {/* {props.gameOver} */}
      <Button onClick={handleOpen}>Show Results</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal}>
          <TableContainer component={Paper} className={classes.container}>
            <Table size="small" aria-label="Elixir Blitz">
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center" ></TableCell>
                  <TableCell align="center">Answer</TableCell>
                  <TableCell align="center">Guess</TableCell>
                  <TableCell align="center">Round</TableCell>
                  <TableCell align="center">Overall</TableCell>
                  <TableCell align="center">Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {answerRows}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </>
  );
};
export default withStyles(styles)(CheckAnswer);