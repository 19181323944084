import { withStyles } from "@material-ui/core/styles";
import Input from "./Input";
const styles = (theme) => ({});
function Deck(props) {
  let randomKeys = Object.keys(props.clashData)
    .map(
      (e, i, a, j = ~~(Math.random() * (a.length - i) + i)) => (
        // eslint-disable-next-line
        ([a[i], a[j]] = [a[j], a[i]]), a[i]
      )
    )
    .slice(0, props.numberOfCards * props.numberOfRounds);
  return (
    <>
      <Input
        randomKeys={randomKeys}
        clashData={props.clashData}
        numberOfCards={props.numberOfCards}
        numberOfRounds={props.numberOfRounds}
      />
    </>
  );
}
export default withStyles(styles)(Deck);
